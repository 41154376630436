<template>
  <section id="forgot-password__confirmation" class="mt-10">
    <auth-forms-wrapper height="" width="448px">
      <template #default>
        <div id="forgot-password__content" class="">
          <v-row no-gutters class="px-9">
            <v-col class="d-flex justify-center">
              <v-img
                id="forgot-password__image"
                alt="Forgot password email sent"
                :aspect-ratio="8.5 / 9"
                :src="$images.passwordResetEmailSent"
              />
            </v-col>
          </v-row>

          <v-row no-gutters id="forgot-password__subtitle">
            <v-col cols="12" class="text-center pt-2">
              <h2 class="subtitle-1 text--primary font-weight-large">
                {{ $dictionary.app.forgotPasswordConfirmation.title }}
              </h2>
              <div
                class="font-weight-medium subtitle-1 text--primary mb-4 mt-2"
              >
                {{ $dictionary.app.forgotPasswordConfirmation.text }}
                <strong>{{ email }}</strong>
              </div>
              <div class="font-weight-medium subtitle-1 text--primary mb-4">
                {{ $dictionary.app.forgotPasswordConfirmation.subtext }}
              </div>
              <v-btn
                large
                block
                class="button font-weight-bold"
                color="primary"
                @click.stop="toLogin"
              >
                Return To Login
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </auth-forms-wrapper>
  </section>
</template>

<script>
import { AuthFormsWrapper } from "@/components/shared";

/**
 * Forgot password confirmation page
 */
export default {
  name: "ForgotPasswordConfirmation",
  /**
   * ---------- Components ------------
   */
  components: {
    AuthFormsWrapper,
  },
  /**
   * -----------Computed attribute ----------
   */
  computed: {
    email() {
      return this.$route.query.email || "";
    },
  },
  /**
   * -----------Methods ---------------
   */
  methods: {
    // navigate to login page
    toLogin() {
      this.$router.push(this.$paths.login);
    },
  },
};
</script>
